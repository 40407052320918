import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';

import './index.css';

import { authProvider } from './authProvider';
import TaskList from './modules/tasks/components/Task_List';

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin={true}>
    {
      ({ login, logout, authenticationState, error, accountInfo }) => {
        console.log(authProvider)
        if (authenticationState === AuthenticationState.Authenticated) {
          return (
            <div className='app'>
            <p>
              <span>Welcome, {accountInfo.account.name}!</span>
          <span>{process.env.REACT_APP_CLIENT_ID}</span>fff
              <button onClick={logout}>Logout</button>
            </p>
            <TaskList />
            </div>
          );
        } else if (authenticationState === AuthenticationState.Unauthenticated) {
          if (error) {
            // Avoid errors when users are logedin to another tenant
            if (error.errorCode === 'login_progress_error') {
              authProvider.clearCache();
            }

            return (
              <p>
                <span>An error occured during authentication, please try again!</span>
                {error.errorMessage, error.errorCode}
                <button onClick={login}>Login</button>
              </p>
            )
          }

          return (
            <p>
              <span>Hey stranger, you look new!</span>
              <button onClick={login}>Login</button>
            </p>
          );
        }
      }
    }
  </AzureAD>,
  document.getElementById('root'));
