import React, { Component } from 'react';
import TaskItem from './TaskItem';

import './Task_List.css';

export default class TaskList extends Component {
  state = {
    tasks: [
      {
        id: 1,
        title: "hallo",
        complete: false
      }
    ]
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      const count = this.state.tasks.length + 2;
      this.setState(prevState => ({
        tasks: [...prevState.tasks, { id: count, title: value, complete: false }]
      }));
      event.target.value = ""
    }
  }

  handleTaskTitleUpdate = (task) => {
    console.log('update event', task);
  }

  render() {
    return (
      <section>
        <header>
          <h2>Task Items</h2>
        </header>
        <div>

          <input type="text" id="newTask" placeholder="Add new..." onKeyPress={this.handleKeyPress} />

        </div>
        <div className="task-list-wrapper">
          {this.state.tasks.map(task => {
            return <TaskItem key={task.id} task={task} OnTaskItemUpdate={this.handleTaskTitleUpdate}/>//this.renderTaskItems(task);
          })}
        </div>

      </section>
    )
  }
}