import { MsalAuthProvider, LoginType } from 'react-aad-msal';

var msalConfig = {
  auth: {
      clientId: "6121d324-64a6-4b3e-8fb7-a9b968726eba",
      authority: "https://login.microsoftonline.com/61e96d11-4625-4d7a-aaf3-045145302c3e",
      redirectURI: process.env.REACT_APP_REDIRECT_URL
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
  }
};


const authenticationParameters = {
  scopes: ["user.read"]
  // scopes: [
  //   '<property (i.e. user.read)>',
  //   'https://veenstraitsolutions.onmicrosoft.com/6121d324-64a6-4b3e-8fb7-a9b968726eba/<scope (i.e. demo.read)>'
  // ]
}

export const authProvider = new MsalAuthProvider(msalConfig, authenticationParameters, LoginType.Redirect)