import React, { Component } from 'react';
import './TaskItem.css';

class TaskItem extends Component {
  state = {
    isHovered: false,
    isEdit: false,
    title: ""
  }

  handleHover = () => {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }));
  }

  handleMouseDown = () => {
    this.setState({ isEdit: true });
  }

  handleMouseLeave = () => {
    if (this.state.isEdit) {
      this.updateTask();
      this.setState({ isEdit: false });
    }
  }

  renderEditTaskTitle = () => {
    if (this.state.title.length === 0) {
      this.setState({ title: this.props.task.title })
    }

    return (
      <input type="text" 
        id="editTask"
        value={this.state.title}
        onChange={(e) => { this.setState({ title: e.target.value }) }}
        onKeyPress={this.handleKeyPress} 
      />)
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.updateTask();
    }
  }

  updateTask = () => {
    this.setState({ title: "", isEdit: false })
    this.props.task.title = this.state.title;
    this.props.OnTaskItemUpdate(this.props.task);
  }

  render() {
    console.log(this.state);
    const { task } = this.props;
    const btnClass = this.state.isHovered ? "ms-Icon ms-Icon--SkypeCircleCheck" : "ms-Icon ms-Icon--CircleRing";

    return (
      <div className="task-item-wrapper">
        <div className="task-item-check" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
          {task.complete ? <i className="ms-Icon ms-Icon--Completed" aria-hidden="true"></i> : <i className={btnClass} aria-hidden="true"></i>}
        </div>
        <div className="task-item-title"
          onMouseDown={this.handleMouseDown}
          onMouseLeave={this.handleMouseLeave}>
          {this.state.isEdit ?
            this.renderEditTaskTitle() : task.title}
        </div>

        {/* <div className="task-item-actions">
          <i className="ms-Icon ms-Icon--CalendarWeek" aria-hidden="true" title="Set to Week"></i>
        </div> */}
      </div>
    )
  }
}

export default TaskItem;